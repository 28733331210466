<template>
  <div class="container">
    <!-- 头部视频区域 -->
    <div class="container-header">
      <video
        class="header-video"
        ref="volume"
        width="100%"
        muted
        autoplay
        loop
        src="https://holodata.prometh.xyz/Video/%E5%90%89%E7%A5%A5%E9%A3%9E%E5%A4%A9-%E7%9F%AD.mp4"
      ></video>
      <div class="container-header-btn">
        <h1 :style="{fontSize:($i18n.locale === 'zh'? '50px':'35px')}">{{ $t("about.title") }}</h1>
        <button class="header-btn1">
          <a
            href="https://holodata.prometh.xyz/Temp/videoDemo/%E5%90%89%E7%A5%A5%E9%A3%9E%E5%A4%A9%E6%9C%89%E5%AD%97%E5%B9%95.mp4"
            target="_black"
            >{{ $t("about.btn1") }}</a
          >
        </button>
        <button class="header-btn2" @click="contact">
          {{ $t("about.btn2") }}
        </button>
      </div>
    </div>
    <!-- 头部视频区域 -->
    <!-- 体积视频区域 -->
    <div class="volumeVideo" ref="volumeVideo">
      <div class="volumeVideo-container">
        <h2 class="title">{{ $t("about.title1") }}</h2>
        <div class="row">
          <span class="purple"></span><span class="yellow"></span>
        </div>
        <p>
          {{ $t("about.content") }}
        </p>
        <!-- <p>{{$t('about.title')}}</p> -->

        <video-player
          class="video-player vjs-custom-skin volumeVideo-video"
          :options="playerOptions"
        ></video-player>
        <button class="volumeVideo-btn" @click="lookChange">
          {{ $t("about.btn3") }}
        </button>
      </div>
    </div>
    <!-- 体积视频区域 -->
    <!-- 特色优势区域 -->
    <div class="feature" ref="feature">
      <div class="feature-container">
        <h2 class="title">{{ $t("about.title2") }}</h2>
        <div class="row">
          <span class="purple"></span>
          <span class="yellow"></span>
        </div>
        <video-player
          class="video-player vjs-custom-skin feature-video"
          :options="playerOptions1"
        ></video-player>
        <div class="feature-box">
          <div class="box">
            <h3>{{ $t("about.title2-box1") }}</h3>
            <p v-html="$t('about.title2-content')"></p>
          </div>
          <div class="box">
            <h3>{{ $t("about.title2-box2") }}</h3>
            <p v-html="$t('about.title2-content2')"></p>
          </div>
          <div class="box">
            <h3>{{ $t("about.title2-box3") }}</h3>
            <p v-html="$t('about.title2-content1')"></p>
          </div>
        </div>
        <img
          class="feature-img"
          :src="$i18n.locale === 'zh'? 'https://holodata.prometh.xyz/webMetaTrade/officialWebsite/title-zh.png': 'https://holodata.prometh.xyz/webMetaTrade/officialWebsite/title-en.png'"
          alt=""
        />
        <div class="feature-form">
          <div class="form">
            <p>{{ $t("about.li1") }}</p>
            <p>{{ $t("about.li2") }}</p>
            <p>{{ $t("about.li3") }}</p>
          </div>
          <div class="form">
            <p>{{ $t("about.li4") }}</p>
            <p>{{ $t("about.li5") }}</p>
            <p>{{ $t("about.li6") }}</p>
          </div>
          <div class="form form1">
            <p>{{ $t("about.li7") }}</p>
            <p>{{ $t("about.li8") }}</p>
            <p>{{ $t("about.li9") }}</p>
          </div>
        </div>
        <p class="result">{{ $t("about.result") }}</p>
        <h2 class="title">{{ $t("about.title3") }}</h2>
        <div class="row">
          <span class="purple"></span>
          <span class="yellow"></span>
        </div>
        <div class="feature-box">
          <div class="box">
            <h3>{{ $t("about.title3-box1") }}</h3>
            <p v-html="$t('about.title3-content')"></p>
          </div>
          <div class="box">
            <h3>{{ $t("about.title3-box2") }}</h3>
            <p v-html="$t('about.title3-content1')"></p>
          </div>
          <div class="box">
            <h3>{{ $t("about.title3-box3") }}</h3>
            <p v-html="$t('about.title3-content2')"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 特色优势区域 -->
    <!-- 提供服务区域 -->
    <div class="serve">
      <div class="serve-container">
        <h2 class="title">{{ $t("about.title4") }}</h2>
        <div class="row">
          <span class="purple"></span>
          <span class="yellow"></span>
        </div>
        <div class="paragraph">
          <p>{{ $t("about.content1") }}</p>
          {{ $t("about.content2") }}
        </div>
        <div class="serve-img">
          <div class="box">
            <p>Step 1</p>
            <div class="imgs">
              <img
                src="https://holodata.prometh.xyz/webMetaTrade/officialWebsite/paishe.png"
                alt=""
              />
            </div>
            <p>{{ $t("about.title4-box1") }}</p>
            <div v-html="$t('about.title4-content1')"></div>
          </div>
          <div class="box box1">
            <p>Step 2</p>
            <div class="imgs">
              <img
                width="100%"
                src="https://holodata.prometh.xyz/webMetaTrade/officialWebsite/photography.png"
                alt=""
              />
            </div>
            <p>{{ $t("about.title4-box2") }}</p>
            <div>{{ $t("about.title4-content2") }}</div>
          </div>
          <div class="box">
            <p>Step 3</p>
            <div class="imgs">
              <img
                width="100%"
                src="https://holodata.prometh.xyz/webMetaTrade/officialWebsite/dance.png"
                alt=""
              />
            </div>
            <p>{{ $t("about.title4-box3") }}</p>
            <div>{{ $t("about.title4-content3") }}</div>
          </div>
        </div>
        <div class="serve-btn" @click="contact">{{ $t("about.btn4") }}</div>
      </div>
    </div>
    <!-- 提供服务区域 -->
    <!-- 应用场景区域 -->
    <div class="scene" ref="scene">
      <div class="scene-container">
        <h2 class="title">{{ $t("about.title5") }}</h2>
        <div class="row">
          <span class="purple"></span>
          <span class="yellow"></span>
        </div>
        <el-row :gutter="70">
          <el-col :span="12" v-for="(item, index) in playerlist" :key="index"
            ><div class="grid-content bg-purple">
              <video-player
                class="video-player vjs-custom-skin"
                :options="item"
              ></video-player>
              <p class="videoTitle">
                {{ $i18n.locale === "zh" ? title[index] : title1[index] }}
              </p>
            </div></el-col
          >
        </el-row>
        <div class="clothing">
          <video-player
            class="video-player vjs-custom-skin"
            :options="fuzhuang"
          ></video-player>
          <p class="videoTitle">{{ $t("about.video") }}</p>
        </div>
      </div>
    </div>
    <!-- 应用场景区域 -->
    <!-- 关于我们区域 -->
    <div class="aboutUs" ref="aboutUs">
      <div class="aboutUs-container">
        <h2 class="title">{{ $t("about.btn4") }}</h2>
        <div class="row">
          <span class="purple"></span>
          <span class="yellow"></span>
        </div>
        <div class="container">
          <div class="box">
            <p>{{ $t("about.phone") }}</p>
            <p>139-2240-6564</p>
            <p class="wechat">{{ $t("footer.concernNumber") }}</p>
            <div class="wechat-img">
              <img
                src="https://holodata.prometh.xyz/webMetaTrade/officialWebsite/wx.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="box">
            <p>{{ $t("about.mail") }}</p>
            <p>lianggangda@prometh.xyz</p>
            <p class="wechat">{{ $t("footer.concernContact") }}</p>
            <div class="wechat-img">
              <img src="@/assets/weixin.jpg" alt="" />
            </div>
          </div>
          <div class="box">
            <div class="box-img">
              <img
                src="https://holodata.prometh.xyz/webMetaTrade/officialWebsite/company.png"
                alt=""
              />
            </div>
            <h3>{{ $t("about.address1") }}</h3>
            <p class="site">{{$t('about.address1-c')}}</p>
          </div>
          <div class="box box1">
            <div class="box-img">
              <img
                src="https://holodata.prometh.xyz/webMetaTrade/officialWebsite/company-bg.png"
                alt=""
              />
            </div>
            <h3>{{ $t("about.address2") }}</h3>
            <p class="site">{{$t('about.address2-b')}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们区域 -->
  </div>
</template>
<script>
import { animate } from "@/utils/animate";
export default {
  name: "AboutUs",
  data() {
    return {
      //用vue-video需要的数据源。
      playerOptions: {
        aspectRatio: "16:9",
        sources: [
          {
            src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E5%96%B5%E5%BD%B1-%E6%88%91%E7%9A%84%E5%BD%B1%E7%89%8716.mp4",
          },
        ],
      },
      playerOptions1: {
        aspectRatio: "16:9",
        sources: [
          {
            src: "https://holodata.prometh.xyz/Temp/chengzi/%E7%BD%91%E7%AB%99%E7%B4%A0%E6%9D%90-%E4%BD%93%E7%A7%AF%E8%A7%86%E9%A2%91%E5%BA%94%E7%94%A8.mp4",
          },
        ],
      },
      playerlist: [
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/bantiao.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E5%96%B5%E5%BD%B1-%E6%88%91%E7%9A%84%E5%BD%B1%E7%89%8716.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E6%97%B6%E8%A3%85%E7%A7%80%EF%BC%882.0%EF%BC%89.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E8%B5%9B%E4%BA%8B%E7%9B%B4%E6%92%AD2.0.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E5%8D%B3%E6%97%B6%E9%80%9A%E8%AE%AF2.0.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E5%B9%BF%E5%91%8A%E5%AE%A3%E4%BC%A02.0.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E4%BD%93%E7%A7%AF%E8%A7%86%E9%A2%91%E6%94%B9%E5%8F%98%E5%9C%A8%E7%BA%BF%E6%95%99%E8%82%B2.mp4",
            },
          ],
        },
        {
          aspectRatio: "16:9",
          sources: [
            {
              src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E4%BD%93%E7%A7%AF%E8%A7%86%E9%A2%91%E6%94%B9%E5%8F%98%E7%94%B5%E5%BD%B1%E6%8B%8D%E6%91%84.mp4",
            },
          ],
        },
      ],
      fuzhuang: {
        aspectRatio: "16:9",
        sources: [
          {
            src: "https://holodata.prometh.xyz/Video/web-meta-trade/%E4%BD%93%E7%A7%AF%E8%A7%86%E9%A2%91%E6%94%B9%E5%8F%98%E6%9C%8D%E8%A3%85%E7%94%B5%E5%95%86.mp4",
          },
        ],
      },
      title: [
        "红色教育",
        "歌曲MV",
        "时装秀",
        "赛事直播",
        "即时通讯",
        "广告宣传",
        "在线教育",
        "电影拍摄",
      ],
      title1: [
        "Red Education",
        "Song/MV",
        "Fashion Show",
        "Sport Live",
        "Instant Messaging",
        "Advertisement",
        "Online Education",
        "Film Production",
      ],
    };
  },
  components: {},
  created() {},
  mounted() {
    //判断跳转的位置。
    if (this.$route.query.goto == "false") {
      this.$nextTick(() => {
        animate(window, this.$refs.feature.offsetTop + 70);
      });
    } else if (this.$route.query.goto == "true") {
      this.$nextTick(() => {
        animate(window, this.$refs.volumeVideo.offsetTop + 70);
      });
    }
  },
  methods: {
    //判断跳转的位置。
    contact() {
      this.$nextTick(() => {
        animate(window, this.$refs.aboutUs.offsetTop-70);
      });
    },
    lookChange() {
      this.$nextTick(() => {
        animate(window, this.$refs.scene.offsetTop-70);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #15131b;
  font-size: 20px;
  .title {
    font-size: 30px;
    margin: 0;
  }
  .row {
    margin-bottom: 20px;
    .purple,
    .yellow {
      display: inline-block;
      width: 55px;
      height: 3px;
      background-color: #ea00d7;
    }
    .yellow {
      margin-left: 10px;
      background-color: #ffc322;
    }
  }
  // 头部视频
  .container-header {
    position: relative;
    text-align: center;
    .header-video {
      display: block;
    }
    .container-header-btn {
      position: absolute;
      bottom: 25%;
      left: 50%;
      color: #fff;
      transform: translateX(-50%);
      h1 {
        font-size: 50px;
        text-shadow: 2px 2px 5px #15131b;
      }
      .header-btn1,
      .header-btn2 {
        color: #fff;
        border: 3px solid #fff;
        background: transparent;
        padding: 15px 30px 13px;
        cursor: pointer;
      }
      .header-btn1 {
        margin-right: 40px;
      }
    }
  }
  // 体积视频
  .volumeVideo {
    background: url("https://holodata.prometh.xyz/webMetaTrade/officialWebsite/%E7%BB%843.png")
      no-repeat top center;
    background-size: contain;
    background-attachment: scroll;
    background-size: 100%;
    .volumeVideo-container {
      color: #fff;
      max-width: 1210px;
      margin: auto;
      text-align: center;
      overflow: hidden;
      padding: 60px 0 50px;
      font-size: 16px;
      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 20px;
      }
      .volumeVideo-video {
        margin: 30px 0;
        display: block;
      }
      .volumeVideo-btn {
        color: #fff;
        border: 3px solid #fff;
        background: transparent;
        padding: 15px 30px 13px;
        cursor: pointer;
      }
    }
  }
  // 特色优势
  .feature {
    background: url("https://holodata.prometh.xyz/webMetaTrade/officialWebsite/%E7%BB%844.png")
      no-repeat top center;
    background-attachment: scroll;
    background-size: 100% 100%;
    .feature-container {
      color: #fff;
      max-width: 1210px;
      margin: auto;
      text-align: center;
      overflow: hidden;
      padding: 80px 0 50px;
      font-size: 16px;
      .feature-video {
        margin: 30px 0 50px;
        display: block;
      }
      .feature-box {
        display: flex;
        justify-content: space-between;
        .box {
          width: 354px;
          font-size: 17px;
          box-sizing: border-box;
          padding: 20px 20px;
          text-align: center;
          background-color: #202027;
          box-shadow: 0 0 5px 0 #0c0c0c;
        }
      }
      .feature-img {
        display: block;
        margin: 50px auto 30px;
      }
      .feature-form {
        max-width: 1000px;
        margin: auto;
        .form {
          display: flex;
          justify-content: space-around;
          font-weight: normal;
          font-size: 20px;
          border-bottom: 1px solid #fff;
          p {
            flex: 1;
          }
        }
        .form1 {
          border-bottom: none;
        }
      }
      .result {
        margin-top: 30px;
        margin-bottom: 80px;
        font-size: 20px;
      }
    }
  }
  // 提供服务
  .serve {
    background: url("https://holodata.prometh.xyz/webMetaTrade/officialWebsite/sadad.png")
      no-repeat top center;
    background-attachment: scroll;
    background-size: 100%;
    .serve-container {
      max-width: 1210px;
      color: #fff;
      margin: auto;
      padding: 90px 0 50px;
      .paragraph {
        font-size: 18px;
        margin: 50px 0;
        p {
          margin: 0;
          margin-bottom: 8px;
        }
      }
      .serve-img {
        display: flex;
      }
      .box {
        flex: 1;
        font-size: 18px;
        .imgs {
          width: 100%;
          height: 200px;
          border-radius: 3px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-out;
            -moz-transition: all 0.5s ease-out;
            -webkit-transition: all 0.5s ease-out;
            -o-transition: all 0.5s ease-out;
          }
          img:hover {
            transform: scale(1.2, 1.2);
          }
        }
      }
      .box1 {
        margin: 0 70px;
      }
      .serve-btn {
        width: 193px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-radius: 27.5px;
        color: #000;
        font-size: 20px;
        margin: 100px 0 50px;
        cursor: pointer;
        background-image: linear-gradient(to right, #fff, #9aa5c4);
      }
    }
  }
  // 应用场景
  .scene {
    background: url("https://holodata.prometh.xyz/webMetaTrade/officialWebsite/%E7%BB%844.png")
      no-repeat top center;
    background-attachment: scroll;
    background-size: 100% 100%;
    .scene-container {
      max-width: 1210px;
      color: #fff;
      margin: auto;
      text-align: center;
      padding: 90px 0 50px;
      .el-row {
        .el-col {
          .videoTitle {
            margin: 20px 0 60px;
          }
        }
      }
      .clothing {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
  // 关于我们
  .aboutUs {
    background: url("https://holodata.prometh.xyz/webMetaTrade/officialWebsite/bg-21.png")
      no-repeat top center;
    background-attachment: scroll;
    background-size: 100% 100%;
    .aboutUs-container {
      max-width: 1210px;
      color: #fff;
      margin: auto;
      padding: 90px 0 50px;
      .container {
        display: flex;
        background-color: transparent;
        .box {
          flex: 1;
          margin-right: 50px;
          p {
            margin: 0;
            margin-bottom: 10px;
          }
          .wechat {
            margin: 60px 0 20px;
          }
          .wechat-img {
            overflow: hidden;
            width: 180px;
            height: 180px;
            border-radius: 5px;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .box-img {
            overflow: hidden;
            width: 250px;
            height: 250px;
            border-radius: 5px;
            img {
              display: block;
              width: 100%;
              height: 100%;
              transition: all 0.5s ease-out;
              -moz-transition: all 0.5s ease-out;
              -webkit-transition: all 0.5s ease-out;
              -o-transition: all 0.5s ease-out;
            }
            img:hover {
              transform: scale(1.2, 1.2);
            }
          }
          h3 {
            font-weight: normal;
            margin: 15px 0;
          }
          .site {
            font-size: 14px;
          }
        }
        .box1 {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
